//@ts-ignore
//@ts-nocheck
export default (editor, opts = {}) => {
  const domc = editor.DomComponents;
  const primaryColor = "#58AA46";
  const whiteColor = "#ffffff";
  const blackColor = "#4A4A4A";
  const borderColor = "#EDEDED";
  const inputBorderColor = "#c1c1c1";

  domc.addType("SEARCH-BOX-COMPONENTS", {
    model: {
      toHTML() {
        return ` 
      <style>
        .cmp-css-product-search-list{
          padding: 20px 10px;
         }
        .cmp-css-cards{
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .cmp-css-card{
          display:flex; 
          justify-content: space-between;
          flex-direction: row-reverse;
          gap: 5px;
          height: auto;
          padding:10px;
          border-radius: 10px;
          border: 2px solid ${borderColor}
        }
        .cmp-css-title{
          color: ${blackColor};
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px 
        }
        .cmp-css-subtitle{
          color: ${blackColor};
          font-weight: 600;
          margin-bottom: 5px 
        }
        .cmp-css-paragraph{
          margin-bottom: 5px;
          color: ${blackColor};
          line-height: 1.5
        }
        .cmp-css-imagefield{
          position: relative;
          margin: auto 0
        }
       .cmp-css-imagefield svg{
          width: 120px;
       }
        .cmp-css-img{
          width:100px;
          height: 100px;
          object-fit: cover;
          position: relative;
        }
        .cmp-css-redirect-add{
          text-decoration: none;
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: ${whiteColor};
          box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;
          color: ${blackColor};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-weight: bold;
          border: none;
          cursor: pointer
        }

      </style>
          <div class="cmp-css-search-container">
           <div class="cmp-css-search-area">
            <div  class="css-cmp-cart-searchBox">
              <a class="cmp-css-cart-back" href="/">
                 <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M1.99994 6L1.29294 6.707L0.585938 6L1.29294 5.293L1.99994 6ZM18.9999 14C18.9999 14.2652 18.8946 14.5196 18.707 14.7071C18.5195 14.8946 18.2652 15 17.9999 15C17.7347 15 17.4804 14.8946 17.2928 14.7071C17.1053 14.5196 16.9999 14.2652 16.9999 14H18.9999ZM6.29294 11.707L1.29294 6.707L2.70694 5.293L7.70694 10.293L6.29294 11.707ZM1.29294 5.293L6.29294 0.292999L7.70694 1.707L2.70694 6.707L1.29294 5.293ZM1.99994 5H11.9999V7H1.99994V5ZM18.9999 12V14H16.9999V12H18.9999ZM11.9999 5C13.8565 5 15.6369 5.7375 16.9497 7.05025C18.2624 8.36301 18.9999 10.1435 18.9999 12H16.9999C16.9999 10.6739 16.4732 9.40215 15.5355 8.46447C14.5978 7.52678 13.326 7 11.9999 7V5Z" fill="#4A4A4A"></path>
                 </svg>
              </a>
             <input  type="text" name="default-name" placeholder="Search the menu" class="css-cmp-cart-search-bar">
             </div>
           </div>
           <div class = "cmp-css-product-search-list">
           </div>

           <div  class="cmp-css-empty-cart" style= "display: none">
              <svg width="145" height="145" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M0.520844 6.88544C0.520844 5.2527 1.16945 3.68683 2.32397 2.53231C3.47849 1.37779 5.04435 0.729187 6.67709 0.729187H11.2573C19.0553 0.729187 23.734 5.97431 26.4017 10.8501C28.1829 14.1006 29.4716 17.8682 30.4813 21.2829L31.3021 21.25H133.89C140.703 21.25 145.628 27.7674 143.756 34.3259L128.751 86.9331C127.406 91.6517 124.56 95.8035 120.644 98.7597C116.728 101.716 111.955 103.316 107.049 103.317H58.2254C53.2799 103.317 48.4711 101.693 44.5382 98.6946C40.6054 95.696 37.7662 91.4888 36.4569 86.7197L30.2186 63.9662L19.8761 29.0972L19.8679 29.0315C18.5874 24.3774 17.389 20.0188 15.5996 16.7683C13.884 13.6081 12.505 13.0417 11.2656 13.0417H6.67709C5.04435 13.0417 3.47849 12.3931 2.32397 11.2386C1.16945 10.084 0.520844 8.51818 0.520844 6.88544ZM53.875 144.375C58.229 144.375 62.4046 142.645 65.4833 139.567C68.5621 136.488 70.2917 132.312 70.2917 127.958C70.2917 123.604 68.5621 119.429 65.4833 116.35C62.4046 113.271 58.229 111.542 53.875 111.542C49.521 111.542 45.3454 113.271 42.2667 116.35C39.188 119.429 37.4583 123.604 37.4583 127.958C37.4583 132.312 39.188 136.488 42.2667 139.567C45.3454 142.645 49.521 144.375 53.875 144.375ZM111.333 144.375C115.687 144.375 119.863 142.645 122.942 139.567C126.02 136.488 127.75 132.312 127.75 127.958C127.75 123.604 126.02 119.429 122.942 116.35C119.863 113.271 115.687 111.542 111.333 111.542C106.979 111.542 102.804 113.271 99.725 116.35C96.6463 119.429 94.9167 123.604 94.9167 127.958C94.9167 132.312 96.6463 136.488 99.725 139.567C102.804 142.645 106.979 144.375 111.333 144.375Z" fill="black" fill-opacity="0.19"></path>
              </svg>
           </div>

           <div class="cmp-css-view-cart" style="display: none">
              <a class="cmp-css-view-button" href="/cart/"></a>
          </div>
       <div>

     <script>
      document.addEventListener("DOMContentLoaded", function () {
        const origin = window.location.origin;

        async function cartList() {
          try {
            let url = origin + "/api/v1/cart/list-items";
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            const totalItems = data?.cart_items?.reduce((acc, item)=> { 
               return acc + Number(item?.quantity)
             }
            ,0)
            const viewCartButton =document.querySelector(".cmp-css-view-cart");
            const viewCartValue =document.querySelector(".cmp-css-view-button");
            if(totalItems > 0){
              viewCartButton.style.display = "block";
              viewCartValue.textContent = "View "+"cart "+"(" + totalItems +")" ;
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      cartList();  
       const productSearch = async (search) => {
          try {
            let url ;
            if(search.length > 0){
               url = origin + "/api/v1/products/?search=" + search;
            }
            const response = await fetch(url);
            
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            const productSearchList = document.querySelector(".cmp-css-product-search-list");
            const cardGroup = document.createElement("div");
            cardGroup.classList.add("cmp-css-cards");

            data?.map((product)=>{
                const singleCards = document.createElement("div");
                singleCards.classList.add("cmp-css-card");
                singleCards.addEventListener("click", ()=>{
                   window.location.href = "/product/" + product.pk  ;
                })
                const cardDetails = document.createElement("div");

                const productTitle = document.createElement("div");
                productTitle.classList.add("cmp-css-title");
                productTitle.textContent = product.title;

                const productSellingPrice = document.createElement("div");
                productSellingPrice.classList.add("cmp-css-subtitle");
                productSellingPrice.textContent = "$" + product.selling_price;

                const productDesc = document.createElement("div");
                productDesc.classList.add("cmp-css-paragraph");
                if (typeof product.short_desc === "string") {
                  productDesc.textContent =
                    product?.short_desc ? product.short_desc.slice(0, 100) + "..." : "";
                } else {
                  productDesc.textContent = "";
                }
                
                const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                svg.setAttribute("width", "150");
                svg.setAttribute("viewBox", "0 0 24 24");
                svg.setAttribute("style", "fill: rgba(0,0,0,0.15);transform: scale(0.75)");
                const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
                path.setAttribute("d", "M2.28 3L1 4.27l2 2V19c0 1.1.9 2 2 2h12.73l2 2L21 21.72 2.28 3m2.55 0L21 19.17V5a2 2 0 0 0-2-2H4.83M8.5 13.5l2.5 3 1-1.25L14.73 18H5l3.5-4.5z");
                svg.appendChild(path);

                const imageField = document.createElement("div");
                imageField.classList.add("cmp-css-imagefield");

                if (product.image) {
                  const productImage = document.createElement("img");
                  productImage.classList.add("cmp-css-img");
                  productImage.src = product.image;
                  imageField.appendChild(productImage);
                } else {
                 imageField.appendChild(svg);
                 }

                const redirectButton = document.createElement("a");
                redirectButton.classList.add("cmp-css-redirect-add");
                redirectButton.textContent = "+";
                redirectButton.href = "/product/" + product.pk ;


                cardDetails.appendChild(productTitle);
                cardDetails.appendChild(productSellingPrice);
                cardDetails.appendChild(productDesc);

                imageField.appendChild(redirectButton);

                singleCards.appendChild(imageField);
                singleCards.appendChild(cardDetails);
                cardGroup.appendChild(singleCards);
            })
          productSearchList.appendChild(cardGroup);

          } catch (error) {
            console.error("Error:", error);
          }
        };
   
    const searchInput = document.querySelector(".css-cmp-cart-search-bar");
      let debounceTimeout;

      searchInput.addEventListener("input", (e) => {
        const search = e.target.value;
        clearTimeout(debounceTimeout);

        debounceTimeout = setTimeout(() => {
        const productSearchList = document.querySelector(".cmp-css-product-search-list");
        productSearchList.innerHTML = "";
        productSearch(search);

       }, 300);
     });

    });
  </script>
        `;
      },
      defaults: {
        tagName: "div",
        copyable: false,
        removable: false,
        droppable: false,
        editable: false,
        attributes: { class: "cmp-css-search-container" },
        components: [
          {
            tagName: "div",
            attributes: { class: "cmp-css-search-area" },
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            components: [
              {
                tagname: "div",
                copyable: false,
                removable: false,
                droppable: false,
                editable: false,
                draggable: false,
                attributes: { class: "css-cmp-cart-searchBox" },
                components: [
                  {
                    tagName: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    content: `
                  <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M1.99994 6L1.29294 6.707L0.585938 6L1.29294 5.293L1.99994 6ZM18.9999 14C18.9999 14.2652 18.8946 14.5196 18.707 14.7071C18.5195 14.8946 18.2652 15 17.9999 15C17.7347 15 17.4804 14.8946 17.2928 14.7071C17.1053 14.5196 16.9999 14.2652 16.9999 14H18.9999ZM6.29294 11.707L1.29294 6.707L2.70694 5.293L7.70694 10.293L6.29294 11.707ZM1.29294 5.293L6.29294 0.292999L7.70694 1.707L2.70694 6.707L1.29294 5.293ZM1.99994 5H11.9999V7H1.99994V5ZM18.9999 12V14H16.9999V12H18.9999ZM11.9999 5C13.8565 5 15.6369 5.7375 16.9497 7.05025C18.2624 8.36301 18.9999 10.1435 18.9999 12H16.9999C16.9999 10.6739 16.4732 9.40215 15.5355 8.46447C14.5978 7.52678 13.326 7 11.9999 7V5Z" fill="#4A4A4A"/>
                 </svg>
                 `,
                    attributes: { class: "cmp-css-cart-back" },
                  },
                  {
                    tagName: "input",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: {
                      type: "text",
                      name: "default-name",
                      placeholder: "Search the menu",
                      class: "css-cmp-cart-search-bar",
                    },
                  },
                ],
              },
            ],
          },
          {
            tagName: "div",
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            content: `
              <svg width="145" height="145" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M0.520844 6.88544C0.520844 5.2527 1.16945 3.68683 2.32397 2.53231C3.47849 1.37779 5.04435 0.729187 6.67709 0.729187H11.2573C19.0553 0.729187 23.734 5.97431 26.4017 10.8501C28.1829 14.1006 29.4716 17.8682 30.4813 21.2829L31.3021 21.25H133.89C140.703 21.25 145.628 27.7674 143.756 34.3259L128.751 86.9331C127.406 91.6517 124.56 95.8035 120.644 98.7597C116.728 101.716 111.955 103.316 107.049 103.317H58.2254C53.2799 103.317 48.4711 101.693 44.5382 98.6946C40.6054 95.696 37.7662 91.4888 36.4569 86.7197L30.2186 63.9662L19.8761 29.0972L19.8679 29.0315C18.5874 24.3774 17.389 20.0188 15.5996 16.7683C13.884 13.6081 12.505 13.0417 11.2656 13.0417H6.67709C5.04435 13.0417 3.47849 12.3931 2.32397 11.2386C1.16945 10.084 0.520844 8.51818 0.520844 6.88544ZM53.875 144.375C58.229 144.375 62.4046 142.645 65.4833 139.567C68.5621 136.488 70.2917 132.312 70.2917 127.958C70.2917 123.604 68.5621 119.429 65.4833 116.35C62.4046 113.271 58.229 111.542 53.875 111.542C49.521 111.542 45.3454 113.271 42.2667 116.35C39.188 119.429 37.4583 123.604 37.4583 127.958C37.4583 132.312 39.188 136.488 42.2667 139.567C45.3454 142.645 49.521 144.375 53.875 144.375ZM111.333 144.375C115.687 144.375 119.863 142.645 122.942 139.567C126.02 136.488 127.75 132.312 127.75 127.958C127.75 123.604 126.02 119.429 122.942 116.35C119.863 113.271 115.687 111.542 111.333 111.542C106.979 111.542 102.804 113.271 99.725 116.35C96.6463 119.429 94.9167 123.604 94.9167 127.958C94.9167 132.312 96.6463 136.488 99.725 139.567C102.804 142.645 106.979 144.375 111.333 144.375Z" fill="black" fill-opacity="0.19"/>
              </svg>
           `,
            attributes: { class: "cmp-css-empty-cart" },
          },
          {
            tagName: "div",
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            attributes: { class: "cmp-css-view-cart" },
            components: [
              {
                type: "button",
                draggable: false,
                copyable: false,
                removable: false,
                droppable: false,
                editable: false,
                attributes: { class: "cmp-css-view-button" },
                content: "View cart (1)",
              },
            ],
          },
        ],
        styles: `
        .cmp-css-search-container{
          overflow-y: auto;
        }
        .cmp-css-search-area{
          padding: 20px 10px 0 10px;
        }
         .css-cmp-cart-searchBox {
          background-color: #F4F4F4;
          border-radius: 50px;
          width: 100%;
          padding: 0px 20px;
          display:flex;
          gap: 10px
        }
        .cmp-css-cart-back{
          margin: auto 0;asd
        }
        .css-cmp-cart-search-bar{
          background-color: #F4F4F4;
          outline: none;
          border: none;
          width: 100%;
          padding: 15px 0;
        }
        .cmp-css-empty-cart{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90vh;
          overflow-y: auto
        }
        .cmp-css-view-cart{
          position: fixed;
          bottom: 0;
          width:100% ;
          background-color: white;
          box-shadow: 0px -18px 28px 0px #00000040;
          padding: 10px
        }
        .cmp-css-view-button{
          display: block;
          text-decoration: none;
          background-color: ${primaryColor};
          text-align: center;
          padding: 10px;
          color: ${whiteColor}
        }
        
        `,
      },
      view: {},
    },
  });
};
