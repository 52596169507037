// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;


  editor.DomComponents.addType("product-category", {
    model: {
      toHTML() {
        return `{% render_product_category %}`
      },
      defaults: {
        attributes: { class: "product-category" },
        components: `
        <div class="category-container">
          <div class="category-card-container">
            <div class="category-card">
              <div class="category-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="category-title">Tailored Jeans</h1>
              </div>
            </div>
            <div class="category-card">
              <div class="category-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="category-title">Tailored Jeans</h1>
              </div>
            </div>
            <div class="category-card">
              <div class="category-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="category-title">Tailored Jeans</h1>
              </div>
            </div>
            <div class="category-card">
              <div class="category-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="category-title">Tailored Jeans</h1>
              </div>
            </div>
            <div class="category-card">
              <div class="category-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="category-title">Tailored Jeans</h1>
              </div>
            </div>
          </div>
        </div>
        `,
        styles: `
          .category-container {
            padding:6px;
            max-width: 1024px;
            margin: auto;
            font-family: Jost;
            background:white;
          }

         .category-card-container {
           display:grid;
           width:100%;
           grid-template-columns: 1fr 1fr 1fr 1fr;
           justify-content: center;
           gap: 12px;
         }
         .category-card {
          box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
          width: 100%;
          margin: auto;
          text-align: center;
          font-family: Jost;
          background:#FFFFFF;
          border-radius: 15px;
          min-width:unset !important
         }

         .category-card-item img {
          border-radius: 15px;
          border: 1px solid #C2BE27;
          width: 100%;
         }

         .category-title {
           font-size: 15px;
           font-weight: 700;
           color: #3E3E3E;
           align-items: center;
         }
         a{
          text-decoration:none;
         }
         
         @media (max-width:600px) {
          .category-card-container {
            display:grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            gap: 12px;
          }
          .category-card{
            min-wdith:150px:
          }
          .product-category{
            padding:20px;
          }
         `,
      }
    },
  });
};
