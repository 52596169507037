// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("feature-section", {
    model: {
      defaults: {
        attributes: { class: "feature-section" },
        components: `
        <div class="feature-container">
          <div class="image-section">
            <img src="jeans3.jpg" alt="Denim Jeans" style="width:100%">
          </div>
          <div class="about-section">
            <div>
              <h6>About Us</h6>
              <h3>Testy and Healthy Food Now Enjoy your test and Explore With Us</h3>
              <p>Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil expetendis in mei. Mei an pericula euripidis, hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an.</p>
              <p>Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea et. Mea facilisis urbanitas moderatius id. Vis ei rationibus definiebas, eu qui purto zril laoreet. Ex error omnium interpretaris pro, alia illum ea vim.</p>
            </div>
          </div>
        </div>
      `,
        styles: `
        .feature-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin:auto;
        }

        .image-section {
          width: 100%;
        }

        .about-section {
          width: 100%;
        }
        .about-section div {
          padding: 10px;
        }

        .about-section h6 {
          color: #C2BE27;
          font-size: 18px;
          font-weight: 500;
          font-family: Poppins;
          font-style: normal;
        }

        .about-section h3 {
          color: #000000;
          font-size: 34px;
          font-weight: 700;
          font-family: Poppins;
          font-style: normal;
        }

        .about-section p {
          color: #000000;
          font-size: 16px;
          font-weight: 400;
          font-family: Poppins;
          font-style: normal;
        }
        @media (max-width: 992px) {
          .container{ flex-direction: column; }
        }
        @media (max-width: 480px) {
          .feature-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .about-section h3 {
          font-size: 28px;
        }
      `,
      },
    },
  });
};
