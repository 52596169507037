// @ts-ignore
import type grapesjs from "grapesjs";
import loadBlocks from "./blocks";
import loadComponents from "./components";
import { PluginOptions, RequiredPluginOptions } from "../../types";

const plugin: grapesjs.Plugin<PluginOptions> = (editor, opts = {}) => {
  const options: RequiredPluginOptions = {
    id: "product-detail",
    label: "Product Detail",
    block: {},
    style: "",
    styleAdditional: "",
    classPrefix: "product-detail",
    ...opts,
  };

  loadBlocks(editor, options);
  loadComponents(editor, options);
};

export default plugin;
