// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;


  Components.addType("footer", {
    model: {
      defaults: {
        attributes: { class: "footer-wrapper" },
        components: `
        <footer class="footer-wrapper">
        <div class="footer-container">
          <div class="footer-row">
            <div class="footer-row-a">
              <h1>Contact Us</h1>
              <hr />
              <ul>
                <li>(02) 12345678</li>
                <li>0412345678</li>
              </ul>
              <button>Call Now</button>
            </div>
  
            <div class="footer-row-b">
              <h1>Address</h1>
              <hr />
              <p>5/423 King George's Road (Corner of King
                George's Road and
                Edgbaston Road
                Beverly Hills NSW 2209
                Australia                
                </p>
              <button>All Products</button>
            </div>
  
            <div class="footer-row-c">
              <h1>Buisness Hours</h1>
              <hr />
              <ul>
                <li>Mon: 7:00 AM - 9:00 PM</li>
                <li>Tue: 7:00 AM - 9:00 PM</li>
                <li>Wed: 7:00 AM - 9:00 PM</li>
                <li>Thur: 7:00 AM - 9:00 PM</li>
                <li>Fri: 7:00 AM - 9:00 PM</li>
                <li>Sat: 7:00 AM - 9:00 PM</li>
              </ul>
            </div>
          </div>
          <hr>
        </div>
       <div class="">
          <div class="footer-row">
            <div class="">
              <p>Copyright &copy; 2023 All Rights Reserved by <a href="#">DibTech</a>.</p>
            </div>
  
            <div class="">
              <ul class="footer-social-icons">
                <li>Facebook</li>
                <li>Twiter</li>
                <li>Dribble</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      `,
        styles: `
        .footer-wrapper { padding: 20px;background:#181818; color: #fff; }
        .footer-container { text-align: left;}
        .footer-row {width;"100%"; display:flex; flex-direction: row; justify-content: space-between; flex-gap: 12px; teext-align: justify; align-content: left;}
        .footer-row-a {
          flex:1;
          margin-right: 8px;
        }
        .footer-row-b {
          flex:1;
          margin-right: 8px;
        }
        .footer-row-c {
          flex:1;
          margin-right: 8px;
        }
        .footer-row-a ul {text-decoration: none; padding-left: 10px;}
        .footer-row-a ul li {text-align:left;}

        .footer-row-a button {
          background-color: #C2BE27;
          border-radius: 80px;
          padding: 8px 10px;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          border: none;
        }
       
        .footer-row-b button {
          background-color: #C2BE27;
          border-radius: 80px;
          padding: 8px 10px;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          border: none;
        }
        .footer-row-c ul {text-decoration: none; padding: 0px;}
        .footer-row-c li {list-style-type: none; text-align: left; align-items: left;}
        .footer-social-icons {display:flex; flex-direction: row; justify-content: space-between;list-style-type: none;}

        @media (max-width: 992px) {
          .footer-row{ flex-direction: column; }
        }
      `,
      },
    },
  });
};


