// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("user-cart", {
    model: {
      toHTML() {
        return `{% render_user_cart_items %}`
      },
      defaults: {
        attributes: { class: "user-cart" },
        components: `
        <div class="user-cart-container">
          <table style="width:100%" class="user-cart-table">
            <thead class="user-cart-table-head">
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>QTY</th>
                <th>GST</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody class="user-cart-table-body">
              <tr>
                <td>
                  <div style="display:flex; gap:2px;">
                    <img src="" alt="a" style="width:60px; height:60px;"/>
                    <p>Tobias</p>
                  </div>
                </td>
                <td>14</td>
                <td>
                  <div class="user-cart-table-body-qty">
                    <a href="#">-</a>
                    <input class="user-cart-table-body-qty-input" type="text" value="01" />
                    <a href="#">+</a>
                  </div>
                </td>
                <td>14</td>
                <td>10</td>
              </tr>
              <tr style="width: 100%;">
              <td>Modifier:<span style="font-weight: normal;"> Prok Meat: AUD 420, Lamb Meat: AUD 30.00 </span></td>
              </tr>
            </tbody>
          </table>

          
        
        <div class="mob-user-cart">
          <div class="mob-user-cart-table-pro">
         
          <div style="padding:2px">
            <img src="" alt="a" style="width:40px; height:34px;"/>
          </div>

          
          <div style="width:160px;">
            <span style="font-weight:bold; text-align: justify;">Traditional Thakali Kahana Set:</span>
            12.00
           </div>

          <div class="mob-user-cart-table-body-qty">
          <a href="#">-</a>
          <input class="user-cart-table-body-qty-input" type="text" value="03" />
          <a href="#">+</a>
          </div>

          <div style="font-weight:bold;">x</div>

          </div>

          <table style="width:100%" class="mob-user-cart-table">
            <thead class="mob-user-cart-table-head">
            <tr>
              <th style="width:90%">Modifier</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody class="mob-user-cart-table-body">
            <tr>
              <td>
              <ul style="list-style-type:none; text-decoration:none; text-align:left; padding:0">
              <li>Prok Meat: AUD 420,</li>
              <li> Lamb Meat: AUD 30.00</li>
              </ul>
              </td>
              <td>100</td>
            </tr>
            </tbody>
          </table>
          
        </div>

        <div class="total-section">
        <div class="user-cart-total-section">
        <div class="user-cart-total-section-span">
          <span class="user-cart-total-section-span-title">
            Sub Total
            </span>
            <span>
            <span>:</span>
            100
          </span>
        </div>
        <div class="user-cart-total-section-span">
          <span class="user-cart-total-section-span-title">
            Discount
            </span>
            <span>
            <span>:</span>
            1.00
          </span>
        </div>
        <div class="user-cart-total-section-span">
          <span class="user-cart-total-section-span-title">
            GST
            </span>
            <span>
            <span>:</span>
            1.00
          </span>
        </div>
        
        <div class="user-cart-total-section-span">
          <span class="user-cart-total-section-span-title">
            Tender
            </span>
            <span>
            <span>:</span>
            100
          </span>
        </div>

        <div class="user-cart-total-section-span">
          <span class="user-cart-total-section-span-title">
            Total
            </span>
            <span>
            <span>:</span>
            100
          </span>
        </div>

        <div class="user-cart-total-section-button">
          <button class="user-checkout-form-button">Update</button>
          <button class="user-checkout-form-button-a">Checkout</button>
        </div>

    </div>
    </div>

        </div>
        `,
        styles: `
        th, td {
          padding: 15px;
          text-align: left;
          color: #3E3E3E;
        }
      
        .user-cart-table-body tr{
          width: 100%;
          border-bottom:1px solid #EBEBEB;
          font-weight: bold;
          font-size: 16px;
        }

        .user-cart-container{
          width: 100%;
          height: 100%;
          padding: 24px;
          font-family: Jost;
        }

        .user-cart-table {
          position: relative;
          border-collapse: collapse;
        }

        .user-cart-table-head {
          position: sticky;
          top: 0;
          background-color: #EBEBEB;
          font-size: 16px;
          font-weight: bold;

        }

        .user-cart-table-body{
          font-size: 16px;
          font-weight: normal;
        }

        .user-cart-table-body-qty {
          display: inline-block;
          margin: 0 auto;
          gap: 2px;
          border: 1px solid #ceeced;
        }

        .user-cart-table-body-qty a {
          text-decoration: none;
          color: #000000;
          padding:8px 10px;
          margin: 0 2px;
          border-radius: 4px;
          height: 30px;
          width: 20px;
        }

        .user-cart-table-body-qty a:hover {
           background: #3b9ac6;
        }

        .user-cart-table-body-qty-input {
          height: 30px;
          width:30px;
          border:none;
        }
        .total-section {
         display:flex; 
         justify-content: right;

        }
      
       .user-cart-total-section{
        width: 50%;
        display: inline-block;
        font-family: Jost;
        font-size: 16px;
        padding: 12px;
        text-align: left;
       }

       .user-cart-total-section h4{
        background-color:#EBEBEB;
        padding:8px;
       }
     
       .user-cart-total-section-span {
        display: flex;
        justify-content: space-between;
        padding:1px 8px;
       }

       .user-cart-total-section-span-title{
        font-weight:bold;
       }
       .user-cart-total-section-button{
        display: flex;
        gap:16px;
        font-family: Poppins;
        margin-top: 16px;
       }
       .user-checkout-form-button {
        background-color: #FAFAFA;
        height:42px;
        color: #595959;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #1E1E1E;
        cursor: pointer;
        border-radius: 2px;
        padding: 0px 10px;
        font-family: Poppins;
    }
  
      .user-checkout-form-button-a {
        background-color: #5931F4;
        height:42px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border: none;
        cursor: pointer;
        border-radius: 2px;
        padding: 0 10px;
    }
      .mob-user-cart{
        display:none !important;
      }
    
      @media (max-width:600px) {
        th, td {
          padding: 0px;
        }
        .user-cart-container{
          padding: 4px;
        }
        .user-cart-table{
          display:none;
        }
        .mob-user-cart{
          display:block !important;
          font-size: 16px;
          font-weight: bold;
        }
        .mob-user-cart-table{
          padding: 12px;
          border-bottom: 1px solid #8B8B8B;
        }
        .mob-user-cart-table-head {
          position: sticky;
          top: 0;
          font-weight: bold;

        }
        .mob-user-cart-table-body{
          font-weight: normal;
        }
    
        .mob-user-cart-table-pro{
          width:100%;
          display:flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #EBEBEB;
          padding:8px 12px;
          gap:2px;
          margin-top:4px;
        }
        .mob-user-cart-table-body-qty {
          display: inline-block;
          margin: 0 auto;
          gap: 2px;
          border: 1px solid #EBEBEB;
          font-weight: bold;
        }
        .mob-user-cart-table-body-qty a {
          text-decoration: none;
          color: #000000;
          margin: 1px 2px;
          padding: 2px;
          border-radius: 4px;
          font-weight: bold;
        }
        .total-section {
          justify-content: left;
         }
      
        .user-cart-table-body-qty-input {
          width: 30px;
        }
        .user-cart-table-body-qty {
          display: block;
        }
        .user-cart-table-body-qty a {
          width: 10px;
        }
        .user-cart-table-body-qty-input{
          width:30px;
        }
        .user-cart-total-section-button{
          display:flex;
          justify-content: space-between;
          align-items: center;
          color: #303030;
        }
        .user-checkout-form-button {
          border: 1px solid #E5A62B;
        }
        .user-checkout-form-button-a {
          background-color: #E5A62B;
          color: #303030;
        }
      }
        `
      },
    },
  });
};