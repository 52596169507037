// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;

  Components.addType("buttonA", {
    model: {
      defaults: {
        attributes: { class: "buttonA" },
        tagName: "button",
        components: `
          Checkout
      `,
        styles: `
        .buttonA {
          background-color: #5931F4;
          height:42px;
          max-width: 150px;
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }

        @media (max-width:600px) {
        .buttonA {
          background-color: #E5A62B;
          color: #303030;
        }
      }
         `,
      },
    },
  });
};