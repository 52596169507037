// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { block, label, id } = opts;

  if (block) {
    editor.Blocks.add(id, {
      media: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
<path d="M1.40002 0.992676C0.84774 0.992676 0.400024 1.44039 0.400024 1.99268C0.400024 2.54496 0.84774 2.99268 1.40002 2.99268V0.992676ZM3.69256 1.99268L4.65779 1.73126C4.53973 1.29536 4.14417 0.992676 3.69256 0.992676V1.99268ZM7.41793 15.7479L6.45271 16.0093C6.58298 16.4903 7.0475 16.802 7.54197 16.7402L7.41793 15.7479ZM18.8806 14.3151L19.0047 15.3073C19.4293 15.2543 19.7732 14.9368 19.8599 14.5177L18.8806 14.3151ZM20.6 6.00462L21.5793 6.20722C21.6402 5.91271 21.5654 5.60641 21.3755 5.37319C21.1856 5.13998 20.9008 5.00462 20.6 5.00462V6.00462ZM4.77913 6.00462L3.8139 6.26603L4.77913 6.00462ZM1.40002 2.99268H3.69256V0.992676H1.40002V2.99268ZM7.54197 16.7402L19.0047 15.3073L18.7566 13.3228L7.2939 14.7556L7.54197 16.7402ZM19.8599 14.5177L21.5793 6.20722L19.6208 5.80201L17.9014 14.1125L19.8599 14.5177ZM2.72733 2.25409L3.8139 6.26603L5.74435 5.7432L4.65779 1.73126L2.72733 2.25409ZM3.8139 6.26603L6.45271 16.0093L8.38316 15.4865L5.74435 5.7432L3.8139 6.26603ZM20.6 5.00462H4.77913V7.00462H20.6V5.00462ZM10 19.4999C10 19.7761 9.77617 19.9999 9.50002 19.9999V21.9999C10.8807 21.9999 12 20.8807 12 19.4999H10ZM9.50002 19.9999C9.22388 19.9999 9.00002 19.7761 9.00002 19.4999H7.00002C7.00002 20.8807 8.11931 21.9999 9.50002 21.9999V19.9999ZM9.00002 19.4999C9.00002 19.2238 9.22388 18.9999 9.50002 18.9999V16.9999C8.11931 16.9999 7.00002 18.1192 7.00002 19.4999H9.00002ZM9.50002 18.9999C9.77617 18.9999 10 19.2238 10 19.4999H12C12 18.1192 10.8807 16.9999 9.50002 16.9999V18.9999ZM18 19.4999C18 19.7761 17.7762 19.9999 17.5 19.9999V21.9999C18.8807 21.9999 20 20.8807 20 19.4999H18ZM17.5 19.9999C17.2239 19.9999 17 19.7761 17 19.4999H15C15 20.8807 16.1193 21.9999 17.5 21.9999V19.9999ZM17 19.4999C17 19.2238 17.2239 18.9999 17.5 18.9999V16.9999C16.1193 16.9999 15 18.1192 15 19.4999H17ZM17.5 18.9999C17.7762 18.9999 18 19.2238 18 19.4999H20C20 18.1192 18.8807 16.9999 17.5 16.9999V18.9999Z" fill="black"/>
</svg>`,
      label,
      category: "Icon",
      select: true,
      content: { type: "cart-icon" },
      ...block,
    });
  }
};
