// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("blog-detail", {
    model: {
      defaults: {
        attributes: { class: "blog-detail" },
        components: `<div>Hello name</div>`,
        styles: `
        .blog-detail { padding: 20px; font-size:20px }
      `,
      },
    },
  });
};
