// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;

  Components.addType("button", {
    model: {
      defaults: {
        attributes: { class: "button", type:"link", href:"www.google.com" },
        tagName: "a",
        components: `
          Update
      `,
        styles: `
        
        .button {
          background-color: #FAFAFA;
          height:42px;
          color: #595959;
          font-size: 16px;
          font-weight: 500;
          border: 1px solid #1E1E1E;
          cursor: pointer;
          border-radius: 2px;
          padding: 0px 10px;
          font-family: Poppins;
        }
       
      @media (max-width:600px) {
        .button {
          border: 1px solid #E5A62B;
        }
      }
         `,
      },
    },
  });
};