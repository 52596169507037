// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default async (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;

  const token = localStorage.getItem("token")

  const response = await fetch("https://dibbuilder.dibsolutions.com.au/api/navigation/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `JWT ${token}`
    },
  })
    .then((response) => response.json())
    .then((data) => data?.map((item) => `
      <a>
        ${item?.name}
      </a>
    `).join(" "));

  Components.addType("navbar", {
    model: {
      defaults: {
        attributes: { class: "navbar" },
        components: `
        <div class="nav">
          <input type="checkbox" id="nav-check" class="nav-check">
          <div class="nav-header">
            <div class="nav-title">
              Logo
            </div>
          </div>

          <div class="nav-links">
            ${response}
          </div>

          
        <div class="nav-icons-cart">
          <div id="item-search">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5003 17.5L13.762 13.755M15.8337 8.74996C15.8337 10.6286 15.0874 12.4303 13.759 13.7586C12.4306 15.087 10.6289 15.8333 8.75033 15.8333C6.87171 15.8333 5.07004 15.087 3.74165 13.7586C2.41327 12.4303 1.66699 10.6286 1.66699 8.74996C1.66699 6.87134 2.41327 5.06967 3.74165 3.74129C5.07004 2.4129 6.87171 1.66663 8.75033 1.66663C10.6289 1.66663 12.4306 2.4129 13.759 3.74129C15.0874 5.06967 15.8337 6.87134 15.8337 8.74996V8.74996Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
         </div>
         <div class="cart-count">
            <a href="/cart">
             <span class="cart-item-count" id="car-item-cart">0</span>
            <svg width="19" height="19" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3885 2.54996C17.3367 2.48273 17.2703 2.42825 17.1942 2.39069C17.1181 2.35313 17.0345 2.3335 16.9496 2.33329H4.11628L3.86628 1.53885C3.83882 1.45355 3.79113 1.37616 3.72728 1.31328C3.66343 1.2504 3.58532 1.20389 3.49961 1.17774L1.22184 0.477738C1.1518 0.456216 1.07821 0.448699 1.00526 0.455618C0.93232 0.462537 0.861453 0.483755 0.79671 0.518061C0.665956 0.587345 0.56808 0.705734 0.524614 0.847182C0.481148 0.988631 0.495652 1.14155 0.564937 1.27231C0.634221 1.40306 0.752609 1.50094 0.894058 1.5444L2.8885 2.15552L5.43295 10.1944L4.52739 10.9388L4.45517 11.0111C4.23122 11.2704 4.10434 11.5995 4.09624 11.942C4.08813 12.2845 4.1993 12.6192 4.41072 12.8888C4.56215 13.073 4.75456 13.2191 4.97258 13.3156C5.1906 13.4121 5.42816 13.4562 5.66628 13.4444H14.9385C15.0858 13.4444 15.2271 13.3859 15.3313 13.2817C15.4355 13.1775 15.4941 13.0362 15.4941 12.8888C15.4941 12.7415 15.4355 12.6002 15.3313 12.496C15.2271 12.3918 15.0858 12.3333 14.9385 12.3333H5.57739C5.51342 12.3311 5.45109 12.3125 5.39642 12.2792C5.34176 12.2458 5.29661 12.199 5.26533 12.1432C5.23406 12.0873 5.21771 12.0243 5.21788 11.9603C5.21804 11.8963 5.23471 11.8334 5.26628 11.7777L6.60517 10.6666H15.1774C15.3043 10.6703 15.4286 10.6304 15.5297 10.5536C15.6308 10.4768 15.7025 10.3677 15.7329 10.2444L17.5107 3.02218C17.5276 2.93936 17.5254 2.85377 17.5042 2.77194C17.483 2.69011 17.4435 2.61419 17.3885 2.54996Z" fill="white"/>
            </svg>  
            </a> 
          </div>
          <div>
            <a href="/dashboard">
              <svg width="19" height="19" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.6116 13.7457 7.77362 12.9988 10 12.9988C12.2264 12.9988 14.3884 13.7457 16.14 15.12C14.43 17.18 12.03 18 10 18Z" fill="white"/>
              </svg> 
            </a>   
         </div>
         <div class="nav-btn">
          <label for="nav-check" class="nav-btn-label">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        </div>
      </div>
        `,
        styles: `
        .cart-count{
          position: relative;
        }
        .cart-item-count{
          position: absolute;
          top: -8px;
          right: -4px;
          color: red
        }
      .nav {
        height: 80px;
        width: 100%;
        background-color: #269D3F;
        position: relative;
        border-bottom: 0.5px solid #C2BE27;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Poppins';
        padding: 20px;
        align-items: center;
      }

      .nav-icons-cart{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }

      .nav-icons svg {
        margin-right: 10px;
        margin-top: 10px;
        cursor: pointer;
      }
      
      .nav-header {
        display: inline;
      }
      
        .nav-title {
        display: inline-block;
        font-size: normal;
        color: #fff;
        padding: 10px 10px 10px 10px;
      }
      
       .nav-btn {
        display: none;
      }
      
       .nav-links {
        display: inline;
        float: right;
        font-size: normal;
      }
      
       .nav-links a {
        display: inline-block;
        padding: 13px 10px 13px 10px;
        text-decoration: none;
        color: #efefef;
      }
      
       .nav-links a:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
      
       .nav-check {
        display: none;
        visibility: hidden !important;
      }
      
      @media (max-width:600px) {
        .nav-icons{
          display: none;
        }
         .nav-btn {
          display: inline-block;
          width: 26px;
          height: 26px;
          margin-left: 2px;          
        }
         .nav-btn-label {
          display: inline-block;
         
        }
         .nav-btn-label:hover,.nav  #nav-check:checked ~ .nav-btn-label {
          background-color: rgba(0, 0, 0, 0.3);
        }
         .nav-btn-label span {
          display: block;
          width: 20px;
          height: 8px;
          border-top: 2px solid #eee;
        }
         .nav-links {
          position: absolute;
          display: block;
          width: 100%;
          background-color: #269D3F;
          height: 0px;
          transition: all 0.3s ease-in;
          overflow-y: hidden;
          top: 80px;
          left: 0px;
          z-index: 999;
        }
         .nav-links a {
          display: block;
          width: 100%;
        }
         #nav-check:not(:checked) ~ .nav-links {
          height: 0px;
        }
         #nav-check:checked ~ .nav-links {
          height: calc(100vh - 50px);
          overflow-y: auto;
        }
      }
      .car-item-cart{
        color:red;
        position: absolute;
        z-index: 99;
        top:18px;
        right:45px;
        background: white:
      }
        `,
      },
    },
  });
};