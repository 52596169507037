// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;
  let icon = {}


  Components.addType("product-detail", {
    model: {
      toHTML() {
        return `{% render_single_product %}`
      },
      defaults: {
        attributes: { class: "product-detail" },
        components: `
        <div class="product-detail">
        <div class="product-detail-container">
          <div class="product-detail-image-section">
            <img src="jeans3.jpg" alt="Denim Jeans" style="width:100%">
          </div>
          <div class="product-detail-section">
            <div class="product-detail-div">
              <h3>Fried Potato</h3>
              <p class="product-detail-price">$19.99</p>
              <div class="product-detail-cart-section">
                <p>Quantity:</p> 
                <input type="number" class="product-detail-input" value= "01" />
                <button class="product-detail-button">Add To Cart</button>
              <div>

              </div>
              </div>
              <p>Category: Beer, more, other etc</p>
        </div>
        </div>
      `,
        styles: `
        .product-detail {
          width: 100%;
          font-family: 'Inter';
        }
        .product-detail-container {
          width: 100%;
          display: flex;
          justify-content: center;
          margin:auto;
          padding: 40px;
        }

        .product-detail-image-section {
          width: 100%;
          border-radius: 15px;
        }

        .product-detail-section {
          width: 100%;
          text-align: left;
          margin: auto;
        }

        .product-detail-div {
          padding: 20px;
        }

        .product-detail-cart-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
         
        }

        .product-detail-section h3 {
          color: #000000;
          font-size: 34px;
          font-weight: 700;
          font-family: Poppins;
          font-style: normal;
        }

        .product-detail-section p {
          color: #000000;
          font-size: 16px;
          font-weight: 400;
          font-family: Poppins;
          font-style: normal;
        }

        .product-detail-input {
          height: 40px;
          width: 60px;
          border-radius: 4px;
        }

        .product-detail-button {
          background-color: #C2BE27;
          border-radius: 5px;
          padding: 8px 10px;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          border: none;
          height: 40px;
        }
        @media (max-width: 640px) {
          .product-detail-container{ flex-direction: column; }
          .product-detail-div{
            padding:0px;
          } 
        }
        @media (max-width: 992px) {
          .container{ flex-direction: column; }
        }
      `,
      },
    },
  });
};
