// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("form", {
    model: {
      defaults: {
        attributes: { class: "carousel" },
        components: `
        <div class="footer-top">
        <div>Section One </div>
        <div>Section Two</div>
        <div>Section Three</div>
        </div>
        <div class="footer-bottom">
        <div>
        left content
</div>
<div>
Right content
</div>
        </div>
      `,
        styles: `
        .footer-wrapper { padding: 20px; }
        .footer-top { display: flex;justify-content: space-between;}
        .footer-bottom { display: flex; justify-content: space-between; }

        @media (max-width: 992px) {
          .cmp-css{ color: darkred; }
          .cmp-css-a { color: darkgreen }
          .cmp-css-b { color: darkblue }
        }
      `,
      },
    },
  });
};
