// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("user-checkout-form", {
    model: {
      toHTML(){
        return `{% render_user_cart_checkout %}`
      },
      defaults: {
        attributes: { class: "user-checkout-form" },
          components:`
        <div class="user-checkout-form-container">
          <div class="user-checkout-form-container-flex">
            <div class="user-checkout-form-container-flex-first">
              <h1>Checkout</h1>
              <h2>Method of delivery</h2>
              <div class="user-checkout-form-button-list">
                <button class="user-checkout-form-button">Pickup</button>
                <button class="user-checkout-form-button-a">Delivery</button>
              </div>
              <div class="user-checkout-form-form">
                 <form>
                   <input type="text" class="user-checkout-input" placeholder="Name" />
                   <input type="text" class="user-checkout-input" placeholder="Phone" />
                   <input type="email" class="user-checkout-input" placeholder="Email" />
                 </form>
              </div>
              <h2>Method of payment</h2>
              <div class="user-checkout-form-button-list">
                <button class="user-checkout-form-button-a">Cash</button>
                <button class="user-checkout-form-button">Card</button>
              </div>
            </div>
            <div class="user-checkout-form-container-flex-second">
              <h2>Your Orders</h2>
              <div class="user-checkout-form-list">
                <img src="" alt="" />
                <span>
                  <p>Check product First</p>
                  <p> x3 </p>
                </span>
              </div>
              <div class="user-checkout-form-total">
                <span>
                  <p>Sub Total</p>
                  <p> 100 </p>
                </span>
                <span>
                  <p>Discount</p>
                  <p> 10 </p>
                </span>
                <span>
                  <p>Total</p>
                  <p> 90 </p>
                </span>
              </div>             
              <button class="user-checkout-form-button-a">Checkout</button>
            </div>        
          </div>
        </div>
        `,
        styles:`
        .user-checkout-form-container{
          width:100%;
          height: 100%;
          padding: 24px;
          font-family: 'Poppins';
          font-style: normal;
        }
        .user-checkout-form-container-flex{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .user-checkout-form-container-flex-first{
          background-color: #FFFFFF;
          padding: 8px;
          flex:65%;
         }
        .user-checkout-form-container-flex-first h1{
          color:#1C1C1C;
          font-size: 35px;
          font-weight: 600;
        }
        .user-checkout-form-container-flex-first h2{
          color:#1C1C1C;
          font-size: 20px;
          font-weight: 500;
        }
        .user-checkout-form-button-list {
          display: flex;
          justify-content: start;
          gap: 24px;
        }
        .user-checkout-form-form {
          width:100%;
          margin-top:20px;
        }
        .user-checkout-form-button {
          background-color: #FAFAFA;
          height:42px;
          max-width: 150px;
          color: #595959;
          font-size: 16px;
          font-weight: 500;
          border: none;
          cursor: pointer;
          border-radius: 5px;
      }
    
        .user-checkout-form-button-a {
          background-color: #5931F4;
          height:42px;
          max-width: 150px;
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
          border: none;
          cursor: pointer;
          border-radius: 5px;
      }
       .user-checkout-input {
          height: 42px;
          width: 44%;
          border-radius: 4px;
          padding: 4px;
          margin-top:4px;
          border:1px solid gray !important;
          margin-right: 4px;
          margin-bottom:4px;
       }
       .user-checkout-form-container-flex-second{
        background-color: #FAFAFA;
        padding: 16px;
        flex:35%;
       }
       .user-checkout-form-list{
        display: flex;
        justify-content: space-between;
        gap:10px;
       }
       .user-checkout-form-total span{ 
        display: flex;
        justify-content: space-between;
        gap:10px;
        margin:auto;
       }
       .user-checkout-form-total span p{ 
        font-size: 16px;
        font-weight: 400;
       }
       @media (max-width:600px) {
        .user-checkout-input{
          width: 98%;
        }
      }
      `
      },
    },
  });
};


// components: `
// <div>
// <div class="user-checkout-form-parent-container">
//   <div>
//   <h1>Billing Address</h1>
//   <div class="user-checkout-form-container">
//     <form class="user-checkout-form">
//       <input type="text" class="user-checkout-input" placeholder="First Name" />
//       <input type="text" class="user-checkout-input" placeholder="Last Name" />
//       <input type="email" class="user-checkout-input" placeholder="Email" />
//       <input type="number" class="user-checkout-input" placeholder="Mobile" />
//       <input type="text" class="user-checkout-input" placeholder="City" />
//       <input type="text" class="user-checkout-input" placeholder="Country" />
//       <input type="text" class="user-checkout-input" placeholder="Postal/Zip" />
//       <input type="text" class="user-checkout-input" placeholder="State/Province" />
//       <input type="text" class="user-checkout-input" placeholder="Address" />
//     </form>
//   </div>
// </div>
// <div>
//   <h1>Shipping Address</h1>
//   <span class="user-checkout-checkbox-container">
//   <input type="checkbox" class="user-checkout-checkbox" />
//   <label>Is Same to Billing Address??</label>
//   </span>
//   <div class="user-checkout-form-container">
//     <form class="user-checkout-form">
//     <input type="text" class="user-checkout-input" placeholder="First Name" />
//     <input type="text" class="user-checkout-input" placeholder="Last Name" />
//     <input type="email" class="user-checkout-input" placeholder="Email" />
//     <input type="number" class="user-checkout-input" placeholder="Mobile" />
//     <input type="text" class="user-checkout-input" placeholder="City" />
//     <input type="text" class="user-checkout-input" placeholder="Country" />
//     <input type="text" class="user-checkout-input" placeholder="Postal/Zip" />
//     <input type="text" class="user-checkout-input" placeholder="State/Province" />
//     <input type="text" class="user-checkout-input" placeholder="Address" />
//     </form>
//   </div>
// </div>
// </div>
// <div class="pagination-button">
// <button class="user-checkout-form-button">Next</button>
// <button class="user-checkout-form-button">Previous</button>
// </div>
// </div>
// `,
// styles: `
// .user-checkout-form-parent-container {
//   display: flex;
//   width:100%;
//   height: auto;
//   gap: 24px;
//   padding: 24px;
// }

// .user-checkout-form-container {
//   display: grid;
//   padding: 6px;
// }

// .user-checkout-form{
//   width:100%;
// }

// .user-checkout-checkbox-container {
//   padding: 4px;
//   padding-left: 15px;
//   color: #000;
// }

// .user-checkout-input {
//   height: 40px;
//   width: 40%;
//   border-radius: 4px;
//   padding: 4px;
//   margin: 8px;
// }

// .pagination-button {
//   display: flex;
//   justify-content: center;
//   gap: 24px;
//   margin-top: 60px;
//   margin-top: 40px;
// }

// .user-checkout-form-button {
//   background-color: #C2BE27;
//   padding: 8px 40px;
//   color: #000000;
//   font-size: 14px;
//   font-weight: 600;
//   border: none;
//   cursor: pointer;
//   border-radius: 80px;
// }`,