// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default (editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  Components.addType("blog-list", {
    model: {
      defaults: {
        tagName: 'input',
        draggable: true, // Can be dropped only inside `form` elements
        droppable: true, // Can't drop other elements inside
        someprop: 'initial value',
        attributes: { // Default attributes
          type: 'text',
          name: 'default-name',
          placeholder: 'Insert text here',
        },
        traits: [
          'name',
          'placeholder',
          { type: 'checkbox', name: 'required' },
        ],
        styles: ``,
      },
      init() {
        this.on('change:someprop', this.handlePropChange);
        // Listen to any attribute change
        this.on('change:attributes', this.handleAttrChange);
        // Listen to title attribute change
        this.on('change:attributes:title', this.handleTitleChange);
      },

      handlePropChange() {
        const { someprop } = this.props();
        console.log('New value of someprop: ', someprop);
      },

      handleAttrChange() {
        console.log('Attributes updated: ', this.getAttributes());
      },

      handleTitleChange() {
        console.log('Attribute title updated: ', this.getAttributes().title);
      },
    },
    view: {
      // Be default, the tag of the element is the same of the model
      tagName: '',
  
      // Add easily component specific listeners with `events`
      // Being component specific (eg. you can't attach here listeners to window)
      // you don't need to care about removing them when the component is removed,
      // they will be managed automatically by the editor
      events: {
        click: 'clickOnElement',
        // You can also make use of event delegation
        // and listen to events bubbled from some inner element
        'dblclick .inner-el': 'innerElClick',
      },
  
      innerElClick(ev) {
        ev.stopPropagation();
        // ...
  
        // If you need you can access the model from any function in the view
        this.model.components('Update inner components');
      },
  
      // On init you can create listeners, like in the model, or start some other
      // function at the beginning
      init({ model }) {
        // Do something in view on model property change
        this.listenTo(model, 'change:prop', this.handlePropChange);
  
        // If you attach listeners on outside objects remember to unbind
        // them in `removed` function in order to avoid memory leaks
        this.onDocClick = this.onDocClick.bind(this);
        document.addEventListener('click', this.onDocClick)
      },
  
      // Callback triggered when the element is removed from the canvas
      removed() {
        document.removeEventListener('click', this.onDocClick)
      },
  
      // Do something with the content once the element is rendered.
      // The DOM element is passed as `el` in the argument object,
      // but you can access it from any function via `this.el`
      onRender({ el }) {
        const btn = document.createElement('button');
        btn.value = '+';
        // This is just an example, AVOID adding events on inner elements,
        // use `events` for these cases
        btn.addEventListener('click', () => {});
        el.appendChild(btn);
      },
  
      // Example of async content
    
    },
  });
};
