// @ts-ignore
import type grapesjs from "grapesjs";
import { RequiredPluginOptions } from "../../types";

export default async(editor: grapesjs.Editor, opts: RequiredPluginOptions) => {
  const { Components } = editor;
  const { id, label } = opts;

  const token = localStorage.getItem("token")

  const categoryResponse = await fetch("https://dibposservice.dibsolutions.com.au/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      query:`
        query{
          categories{
            edges {
              node {
                id
                pk
                name
                contentTypeId
                status
                parent {
                  id
                  pk
                  name
                }
                children {
                  edges {
                    node {
                      id
                      pk
                      status
                      contentTypeId
                      name
                      parent {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
    })
  })
    .then((response) => response.json())
    .then((data) => data.data.categories.edges?.filter((item:any, index:number)=>item?.node?.status !== "MODIFIER")?.map((filterItem,filterIndex)=>({id:filterItem?.node?.pk, name:filterItem?.node?.name})));


  Components.addType("product-list", {
    model: {
      toHTML(){
        let categoryId = this.getAttributes().category;
        return `{% render_products '${categoryId}' %}`
      },
      defaults: {
        categoryId: "",
        droppable:false,
        traits: [
          {
            type: 'select', // Type of the trait
            label: 'Category', // The label you will see in Settings
            name: 'category', // The name of the attribute/property to use on component
            options: categoryResponse,
          }
        ],
        attributes: { class: "product-list" },
        components: `
        <div class="product-container">
          <div class="product-card-container">
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
            <div class="product-card">
              <div class="product-card-item">
                <img src="#" alt="Denim Jeans">
                <h1 class="product-title">Tailored Jeans</h1>
                <p class="price">$19.99</p>
                <button class="product-shop-button">Shop Now</button>
              </div>
            </div>
          </div>
        </div>
        `,
        styles: `
        .product-container {
          padding:6px;
          max-width: 1024px;
          margin: auto;
          font-family: Jost;
          background:white;
        }

       .product-card-container {
         display:grid;
         width:100%;
         grid-template-columns: 1fr 1fr 1fr 1fr;
         justify-content: center;
         gap: 12px;
       }
       .product-card {
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
        width: 100%;
        margin: auto;
        text-align: center;
        font-family: Jost;
        background:#FFFFFF;
        border-radius: 15px;
        min-width:unset !important
       }

       .product-card-item img {
        border-radius: 15px;
        border: 1px solid #C2BE27;
        width: 100%;
       }

       .product-title {
         font-size: 15px;
         font-weight: 700;
         color: #3E3E3E;
         align-items: center;
       }
       a{
        text-decoration:none;
       }
       .product-shop-button {
        background-color: #C2BE27;
        border-radius: 80px;
        padding: 8px 12px;
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        border: none;
        margin-bottom:32px;
       }
       
       @media (max-width:600px) {
        .product-card-container {
          display:grid;
          grid-template-columns: 1fr 1fr;
          justify-content: center;
          gap: 12px;
        }
        .product-card{
          min-wdith:150px:
        }
        .product-list{
          padding:20px;
        }
      }
       `,
      },
      init() {
        this.on('change:attributes:category', this.handleTypeChange);
      },
  
      handleTypeChange() {
        this.addAttributes({categoryId:this.getAttributes().category});
        console.log('Input type changed to: ', this.getAttributes().category);
      },
    },
  });
};
